import { Trans } from '@lingui/macro'
import { useWeb3React } from "@web3-react/core"
import { useIsDarkMode } from 'state/user/hooks'
import CenteringDiv from "components/centeringDiv"
import Loader from "components/Loader"
import { getChainInfo } from "constants/chainInfo"
import HydrogenNucleusHelper from "lib/utils/HydrogenNucleusHelper"
import TradePage from "pages/Trade"
import { Currency, CurrencyAmount, Percent, Price, Token, TradeType } from '@uniswap/sdk-core'
import { Field, PriceField, PairState, DepositState, WithdrawState, BotManagementState } from '../../state/botManagement/actions'
import {
  useDerivedBotManagementInfo,
  useBotManagementActionHandlers,
  useBotManagementState,
} from '../../state/botManagement/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useDefaultsFromURLSearch } from "state/bot/hooks"
import { useBotsState/*, useBotInfo*/ } from "state/statsApi/hooks"
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import styled, { useTheme } from 'styled-components/macro'
import { useToggleWalletModal } from 'state/application/hooks'
import { useExpertModeManager } from '../../state/user/hooks'
import { supportedChainId } from '../../utils/supportedChainId'
import { ApprovalState, useApproveCallback, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useStablecoinValue } from '../../hooks/useStablecoinPrice'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { useTransactionAdder } from 'state/transactions/hooks'
import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary, ButtonYellow } from '../../components/Button'
import { ArrowWrapper, Dots, PageWrapper, SwapCallbackError, SwapWrapper } from '../../components/swap/styleds'
import { AutoRow, RowBetween, RowFixed } from '../../components/Row'
import { LinkStyledButton, ThemedText } from '../../theme'
import BotCard from './BotCard'
import { currencyId } from 'utils/currencyId'
import PriceSelectors from 'components/PriceSelectors'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import tryParseCurrencyAmount2 from 'lib/utils/tryParseCurrencyAmount2'
import tryParseCurrencyAmount3 from 'lib/utils/tryParseCurrencyAmount3'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import CurrencyInputPanel2 from 'components/CurrencyInputPanel/CurrencyInputPanel2'
import { AutoColumn } from '../../components/Column'
import { BOOM_BOTS_NFT_ADDRESSES } from 'constants/addresses'
import { BOOM_BOTS_NFT_VERSION } from 'constants/index'
//import nucleusAbiV101 from 'data/abi/Hydrogen/HydrogenNucleusV101.json'
import { formatUnits, Interface } from 'ethers/lib/utils'
import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { parseUnits } from '@ethersproject/units'
import { AddressZero, WeiPerEther } from '@ethersproject/constants'
import { currencyAmountToString, currencyAmountToBigNumber } from 'lib/utils/currencyAmountToString'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { TransactionType } from 'state/transactions/types'
import { stringValueIsPositiveFloat } from 'utils/stringValueIsPositiveFloat'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
//import ConfirmDepositModal from 'components/botManagement/ConfirmDepositModal'
//import ConfirmWithdrawModal from 'components/botManagement/ConfirmWithdrawModal'
//import ConfirmSetPricesLimitOrderModal from 'components/botManagement/ConfirmSetPricesLimitOrderModal'
import { colors } from 'theme/colors'
import { determinePairOrder } from './../Bot/determinePairOrder'
import { formatTransactionAmount, priceToPreciseFloat } from 'utils/formatNumbers'
import PriceSelectorSingle from 'components/PriceSelector/PriceSelectorSingle'
import { getChainInfoOrDefault } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import BalancesContent from './BalancesContent'
import InstalledModulesContent from './InstalledModulesContent'

const ContentLayout = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 1em;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const BotImageCardContainer = styled.div`
  display: inline;
`

const BotImageCard = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 42px;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.7);
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 64px #CDCCCCA1;
  }
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} box-shadow`};

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    width: 500px;
    height: 500px;
    border-radius: 42px;
  }
`
/*
&:hover {
  width: 600px;
  height: 600px;
}
*/
const FirstRowContainer = styled.div`
  width: 100%;
  height: 500px;

  @media screen and (max-width: 800px) {
    height: 660px;
  }
`

const FirstRowContainerLeft = styled.div`
  display: inline-block;
  height: 500px;
  width: 50%;

  @media screen and (max-width: 800px) {
    display: block;
    width: 100%;
  }
`

const FirstRowSpacer = styled.div`
  display: inline-block;
  width: 5%;

  @media screen and (max-width: 800px) {
    display: block;
    height: 50px;
  }
`

const FirstRowContainerRight = styled.div`
  display: inline-block;
  height: 500px;
  width: 45%;
  position: relative;
  top: -75px;

  @media screen and (max-width: 800px) {
    display: block;
    width: 100%;
    padding: 0;
    position: inherit;
  }
`
/*
position: relative;
top: -75px;
*/

const Card = styled.div<{ isDarkMode: boolean }>`
  padding: 20px;
  min-width: 500px;
  border-radius: 24px;
  color: ${({ theme }) => theme.textPrimary};
  text-decoration: none;
  background-color: ${({ isDarkMode, theme }) =>
    isDarkMode
      ? theme.backgroundModule
      : 'white'};
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.15);
`

const CardTitleText = styled.p`
  margin: 0;
  font-size: 20px;
`

const CardBodyText = styled.p`
  margin: 0;
`
/*
const Tab = styled.p<{ isDarkMode: boolean, isSelected: boolean }>`
  font-size: 20px;
  display: inline;
  margin: 0 16px;
  cursor: pointer;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid black;
  background: ${({ isDarkMode, isSelected, theme }) =>
    isSelected
      ? isDarkMode
        ? '#ffffff11'
        : 'white'
      : ''};
  :hover {
    background: ${({ isDarkMode, isSelected, theme }) =>
      isDarkMode
        ? '#ffffff11'
        : 'white'};
  }
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} background-color`};
`
*/
const OpenBotManagementButtonStyled = styled.div<{ isDarkMode: boolean }>`
  font-size: 20px;
  cursor: pointer;
  padding: 0px 24px;
  border-radius: 8px;
  border: 1px solid black;
  :hover {
    background: ${({ isDarkMode, theme }) =>
      isDarkMode
        ? '#ffffff11'
        : 'white'};
  }
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} background-color`};
`

const OrderTypeContainer = styled.div`
  margin: 50px 20px 20px 20px;
`
//position: fixed;
//bottom: 50px;

const OrderTypeSelector = styled.div<{ isDarkMode: boolean, isSelected?:boolean|undefined }>`
  cursor: pointer;
  margin: 0px 10px;
  display: inline-block;
  padding: 0 20px;
  border-radius: 24px;
  color: ${({ theme }) => theme.textPrimary};
  background-color: ${({ isDarkMode, isSelected, theme }) =>
    isSelected
      ? isDarkMode
        ? '#2C3444'
        : '#ccddff'
      : isDarkMode
        ? theme.backgroundModule
        : 'white'};
  border: 1px solid ${({ isSelected, theme, isDarkMode }) =>
    isSelected
      ? (isDarkMode ? theme.backgroundInteractive : theme.textTertiary)
      : `transparent`};
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.15);
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} border`};
  &:hover {
    border: 1px solid ${({ theme, isDarkMode }) => (isDarkMode ? theme.backgroundInteractive : theme.textTertiary)};
  }
`

const OrderTypeSelectorTextContainer = styled(CenteringDiv)<{ isDarkMode: boolean }>`
  width: 140px;
  font-size: 20px;
  font-weight: 400;
  color: ${({ isDarkMode }) =>
    isDarkMode
      ? 'white'
      : 'black'};
`

const Tab = styled.div<{ isDarkMode: boolean, isSelected?:boolean|undefined }>`
  cursor: pointer;
  margin: 0px 10px;
  display: inline-block;
  padding: 0 20px;
  border-radius: 24px;
  color: ${({ theme }) => theme.textPrimary};
  background-color: ${({ isDarkMode, isSelected, theme }) =>
    isSelected
      ? isDarkMode
        ? '#2C3444'
        : '#ccddff'
      : isDarkMode
        ? theme.backgroundModule
        : 'white'};
  border: 1px solid ${({ isSelected, theme, isDarkMode }) =>
    isSelected
      ? (isDarkMode ? theme.backgroundInteractive : theme.textTertiary)
      : `transparent`};
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.15);
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} border`};
  &:hover {
    border: 1px solid ${({ theme, isDarkMode }) => (isDarkMode ? theme.backgroundInteractive : theme.textTertiary)};
  }
`

const TabTextContainer = styled(CenteringDiv)<{ isDarkMode: boolean }>`
  width: 140px;
  font-size: 16px;
  font-weight: 400;
  color: ${({ isDarkMode }) =>
    isDarkMode
      ? 'white'
      : 'black'};
`

const SwapWrapperWrapper = styled.div`
  margin-bottom: 16px;
`

const SwapWrapperInner = styled.div`
  margin: 8px 12px;
`

const MarketPriceText = styled.p`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 13px;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 0;
`

const PairCardMessageText = styled.p`
  margin: 0.5rem 0 0 0;
  font-size: 13px;
`

const ExternalLink = styled.a`
  text-decoration: none;
  &:hover {
    color: #1348BF;
  }
`

export default function BotManagementCard(props:any) {
  const navigate = useNavigate()
  const { account, chainId, provider } = useWeb3React()
  const botsState = useBotsState() as any
  //const botInfo = usebotInfo() as any
  const isDarkMode = useIsDarkMode()
  //const { botID } = props
  //console.log("in bot page", {botManagementState})

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const urlLoadedTokens = [] as any[]
  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()
  // swap state
  const currentState = useBotManagementState()
  const { pairs, deposits, withdraws, recipient, botID, limitOrder } = currentState
  const {
    currenciesById,
    currencyBalancesById,
    atLeastOnePairsInfoFilled,
    allPairsInfoFilled,
    isLimitOrderInfoFilled,
    atLeastOneDepositAmountFilled,
    atLeastOneWithdrawAmountFilled,
  } = useDerivedBotManagementInfo()

  const { onCurrencySelection, onPriceInput, onDepositAmountInput, onWithdrawAmountInput, onReplaceBotManagementState, onClearBotManagementState } = useBotManagementActionHandlers()

  const { address: recipientAddress } = useENSAddress(recipient)

  const addTransaction = useTransactionAdder()

  const loading = botsState?.loading
  const bot = botsState?.botlist[botID]

  //useMemo(() => {
    //console.log("rendering BotManagementCard")
    //console.log({ botID, bot })
  //}, [botID, bot])

  const {
    nativeCurrency: { symbol: nativeCurrencySymbol },
    explorer,
  } = getChainInfoOrDefault(chainId)

  const monospace = "'Roboto Mono',monospace"
  const etherscanLinkAccount = (account:string, size="sm") => {
    const account2 = ( (size == "sm")
      ? (<>{account.substring(0,21)}{<br/>}{account.substring(21,42)}</>)
      : account)

    return (
      <ExternalLink href={`${explorer}address/${account}`} target="_blank">
        <code style={{fontFamily:monospace}}>
          {account2}
        </code>
      </ExternalLink>
    )
  }

  const etherscanLinkToken = (symbol:string, address:string) => {
    //<ExternalLink href={`${explorer}token/${address}`} target="_blank">
    return (
      <ExternalLink href={`${explorer}address/${address}`} target="_blank">
        <code style={{fontFamily:monospace}}>
          {symbol}
        </code>
      </ExternalLink>
    )
  }

  const [openTab, setOpenTab] = useState('Balances')

  const ButtonTab = (props:any) => {
    const { tabName, selectedTabName } = props
    const isSelected = tabName==selectedTabName
    const onClick = () => { setOpenTab && setOpenTab(tabName) }
    //console.log("in ButtonTab")
    //console.log({ tabName, selectedTabName, isSelected })
    return (
      <Tab isDarkMode={isDarkMode} isSelected={isSelected} onClick={onClick}>
        <TabTextContainer isDarkMode={isDarkMode}>
          <p>{tabName}</p>
        </TabTextContainer>
      </Tab>
    )
    return isSelected ? (
      <p onClick={onClick}>{`${tabName} (selected)`}</p>
    ) : (
      <p onClick={onClick}>{`${tabName}`}</p>
    )
  }

  const tabs = useMemo(() => {
    return (
      <div style={{paddingTop:"20px",paddingBottom:"20px"}}>
        <CenteringDiv>
          <div>
            <ButtonTab tabName="Balances" selectedTabName={openTab}/>
            <ButtonTab tabName="Installed Modules" selectedTabName={openTab}/>
            <ButtonTab tabName="Module Registry" selectedTabName={openTab}/>

          </div>
        </CenteringDiv>
      </div>
    )
  }, [openTab, setOpenTab])


  const moduleRegistryContent = useMemo(() => {
    return (
      <CenteringDiv>
        <p>Module registry coming soon</p>
      </CenteringDiv>
    )
  }, [bot])

  const content = useMemo(() => {
    if(openTab == "Balances") return <BalancesContent bot={bot}/>
    else if(openTab == "Installed Modules") return <InstalledModulesContent bot={bot}/>
    else if(openTab == "Module Registry") return moduleRegistryContent
    else return <p>{`unknown tab ${openTab}`}</p>
  }, [openTab, moduleRegistryContent])

  const botManagementInterface = useMemo(() => {
    return (
      <SwapWrapper style={{width:"100%",paddingBottom:"20px",marginTop:"40px"}}>
        <div>
          {tabs}
          {/*
          <CenteringDiv>
            <p>Contents here 2</p>
          </CenteringDiv>
          <CenteringDiv>
            <pre style={{fontFamily:monospace}}>{`isFocus: ${!!bot.isFocus}`}</pre>
          </CenteringDiv>
          <CenteringDiv>
            <pre style={{fontFamily:monospace}}>{JSON.stringify(bot, undefined, 2)}</pre>
          </CenteringDiv>
          */}
          {content}
        </div>
      </SwapWrapper>
    )
  }, [botID, bot, account, tabs, content])

  return botManagementInterface
}
