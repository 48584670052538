import { useMemo, useState, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { PageWrapper } from '../../components/swap/styleds'
import { useIsDarkMode } from 'state/user/hooks'
import { useWeb3React } from '@web3-react/core'
import { useBotsState } from 'state/statsApi/hooks'
import CenteringDiv from 'components/centeringDiv'
import Loader from 'components/Loader'
import { useAllTokens, useCurrencies } from 'hooks/Tokens'
import ArrowLimitOrderWhite from '../../assets/images/arrow-limit-order-white2.png'
import ArrowGridOrderWhite from '../../assets/images/arrow-grid-order-white2.png'
//import HydrogenNucleusHelper from 'lib/utils/HydrogenNucleusHelper'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { formatUnits } from 'ethers/lib/utils'
import { deduplicateArray } from 'lib/utils/arrays'
import { currencyAmountToString } from 'lib/utils/currencyAmountToString'
import { Price } from '@uniswap/sdk-core'
import TradePrice from 'components/swap/TradePrice'
import { formatTransactionAmount, priceToPreciseFloat } from 'utils/formatNumbers'
import { BigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import BotCard from 'components/Bot/BotCard'
import BotCardInListContainer from 'components/Bot/BotCardInListContainer'
import BotListPaginated from 'components/Bot/BotListPaginated'
import { getChainInfo } from 'constants/chainInfo'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { SupportedChainId } from 'constants/chains'
import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary, ButtonYellow } from '../../components/Button'
import { Trans } from '@lingui/macro'
import { useToggleWalletModal } from 'state/application/hooks'

const SelectorContainer = styled.div`
  margin: 20px 0 40px 0;
`

const Selector = styled.p<{ isDarkMode: boolean }>`
  display: inline;
  font-size: 16px;
  padding: 15px 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    font-size: 24px;
    padding: 15px 30px;
    margin-left: 15px;
    margin-right: 15px;
  }
`

const SelectorSelected = styled(Selector)`
  color: white;
  background: ${({ isDarkMode }) =>
    isDarkMode
      ? 'linear-gradient(93.06deg, #003D68 2.66%, #006FA5 98.99%)'
      : 'linear-gradient(93.06deg, #008BCE 2.66%, #66B5EC 98.99%)'};
  transition: ${({ theme }) => `all ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
  &:hover {
    box-shadow: 0px 0px 16px 0px #003D68;
    background: ${({ isDarkMode }) =>
      isDarkMode
        ? 'linear-gradient(93.06deg, #104D78 2.66%, #107FB5 98.99%)'
        : 'linear-gradient(93.06deg, #008BCE 2.66%, #66B5EC 98.99%)'};
  }
`

const SelectorDeselected = styled(Selector)`
  color: gray;
  background: ${({ isDarkMode }) =>
    isDarkMode
      ? 'linear-gradient(93.06deg, #0E2332 2.66%, #0A2E47 98.99%)'
      : 'linear-gradient(93.06deg, #008BCE 2.66%, #66B5EC 98.99%)'};
  transition: ${({ theme }) => `all ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
  &:hover {
    color: white;
    box-shadow: 0px 0px 16px 0px #003D68;
    background: ${({ isDarkMode }) =>
      isDarkMode
        ? 'linear-gradient(93.06deg, #0A2E47 2.66%, #0B3756 98.99%)'
        : 'linear-gradient(93.06deg, #008BCE 2.66%, #66B5EC 98.99%)'};
    }
  }
`

export default function BotsPage() {
  const isDarkMode = useIsDarkMode()
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const botsState = useBotsState() as any
  const defaultTokens = useAllTokens() as any

  const [viewFilter, setViewFilter] = useState("allbots") as any
  const clickYourBots = () => setViewFilter("mybots")
  const clickAllBots = () => setViewFilter("allbots")

  const allBotIDs = useMemo(() => {
    //console.log("fetching allBotIDs from")
    //console.log(chainId, botsState)
    if(!botsState || botsState.loading || !chainId || botsState.chainId != chainId) return [] as string[]
    var keys = Object.keys(botsState.botlist)
    keys = keys.sort((a:string,b:string) => parseInt(b)-parseInt(a))
    return keys
  }, [account, chainId, botsState, viewFilter])

  const myBotIDs = useMemo(() => {
    if(!account) return [] as string[]
    return allBotIDs.filter((botID:string) => {
      return (botsState.botlist[botID].owner == account)
    })
  }, [account, allBotIDs])

  const createNewBotLink = (upperText:string) => {
    return (
      <CenteringDiv>
        <div>
          <CenteringDiv>
            <h3>{upperText}</h3>
          </CenteringDiv>
          <CenteringDiv>
            <Link to={'/create'} style={{textDecoration:"none"}}>
              Create new bot
            </Link>
          </CenteringDiv>
        </div>
      </CenteringDiv>
    )
  }

  const botListPaginated = (botIDs:string[]) => {
    //const { botIDs } = this.props
    return (
      <div>
        {botIDs.map((botID:string) => (
          <BotCardInListContainer botID={botID} key={botID}/>
        ))}
      </div>
    )
  }

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  const innerContent = useMemo(() => {
    if(viewFilter == "mybots" && !account) return (
      <CenteringDiv style={{paddingTop:"50px"}}>
        <ButtonLight onClick={toggleWalletModal} fontWeight={600}>
          <Trans>Connect wallet to view your bots</Trans>
        </ButtonLight>
      </CenteringDiv>
    )
    if(!chainId || !botsState || botsState.loading) return <CenteringDiv><Loader/></CenteringDiv>
    if(viewFilter == "mybots") {
      if(!myBotIDs || myBotIDs.length == 0) return createNewBotLink(`No bots owned by ${account}`)
    }
    if(viewFilter == "allbots") {
      if(!allBotIDs || allBotIDs.length == 0) return createNewBotLink(`No bots found`)
    }
    return (
      <div>
        {/*(viewFilter == "mybots") ? botListPaginated(myBotIDs) : botListPaginated(allBotIDs)*/}
        {(viewFilter == "mybots") ? <BotListPaginated botIDs={myBotIDs}/> : <BotListPaginated botIDs={allBotIDs}/>}
        {createNewBotLink("Or")}
      </div>
    )
  }, [chainId, botsState, viewFilter, account, myBotIDs, allBotIDs, toggleWalletModal])

  const upperContent = useMemo(() => {
    return (
      <CenteringDiv>
        { (viewFilter=="mybots") ? (
          <SelectorContainer>
            <SelectorDeselected isDarkMode={isDarkMode} onClick={clickAllBots}>View All Bots</SelectorDeselected>
            <SelectorSelected isDarkMode={isDarkMode} onClick={clickYourBots}>View Your Bots</SelectorSelected>
          </SelectorContainer>
        ) : (
          <SelectorContainer>
            <SelectorSelected isDarkMode={isDarkMode} onClick={clickAllBots}>View All Bots</SelectorSelected>
            <SelectorDeselected isDarkMode={isDarkMode} onClick={clickYourBots}>View Your Bots</SelectorDeselected>
          </SelectorContainer>
        )}
      </CenteringDiv>
    )
  }, [isDarkMode, viewFilter, clickYourBots, clickAllBots])



  //console.log({chainId, exists: !!chainId})
  //const info = chainId ? getChainInfo(chainId) : undefined

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState<SupportedChainId | undefined>(undefined)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onSelectChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      setPendingChainId(undefined)
      setIsOpen(false)
    },
    [selectChain, setIsOpen]
  )

  const targetChain = 168587773

  // chain swapping button
  if(!chainId) return (
    <CenteringDiv>
      <Loader/>
    </CenteringDiv>
  )
  if(chainId != targetChain) return (
    <div style={{paddingTop:"50px"}}>
      <CenteringDiv>
        <ButtonLight onClick={() => onSelectChain(targetChain)} fontWeight={600}>
          <Trans>Connect wallet to Blast Sepolia</Trans>
        </ButtonLight>
      </CenteringDiv>
    </div>
  )

  // return
  return (
    <div style={{paddingTop:"50px"}}>
      {upperContent}
      {innerContent}
    </div>
  )
}
