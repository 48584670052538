import { updateStatsApiStateData, updateStatsApiBotInfo } from './actions'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { AppState } from '../index'
import { useWeb3React } from '@web3-react/core'
import { HttpClient } from './HttpClient'
import { BOOM_BOTS_NFT_VERSION } from 'constants/index'
import { useBotManagementState } from './../botManagement/hooks'

export function useBotsState(): AppState['statsApi'] {
  return useAppSelector((state) => state.statsApi.botsState)
}

export function useBotInfo(): AppState['statsApi'] {
  return useAppSelector((state) => state.statsApi.botInfo)
}

export function useUpdateStatsApiState(): (apiResponse: any) => void {
  const dispatch = useAppDispatch()
  return useCallback((apiResponse: any) => dispatch(updateStatsApiStateData({ apiResponse })), [
    dispatch,
  ])
}

// calls the api botsState endpoint and stores the response
export function useRefreshStatsApiState() {
  const { chainId } = useWeb3React()

  const updateStatsApiState = useUpdateStatsApiState()
  const httpClient = new HttpClient()
  const botManagementState = useBotManagementState()

  function refreshBotsState() {
    if(!chainId) return
    console.log("in hooks.refreshBotsState")
    console.log(botManagementState)
    const stateUrl = `https://stats.boombots.xyz/state/?chainID=${chainId}&v=${BOOM_BOTS_NFT_VERSION}`
    httpClient.get(stateUrl, false).then((botsState:any) => {
      botsState.chainId = chainId
      updateStatsApiState(botsState)
    })
  }

  return useCallback(() => {
    refreshBotsState()
  }, [])
}

// after new bot is created
export function usePollStatsApiForBotID() {
  const { chainId } = useWeb3React()

  const updateStatsApiState = useUpdateStatsApiState()
  const httpClient = new HttpClient()

  async function refreshBotsStateForBotID(botID: number) {
    return new Promise(async (resolve,reject) => {
      if(!chainId) {
        resolve(undefined)
        return
      }
      const stateUrl = `https://stats.boombots.xyz/state/?chainID=${chainId}&v=${BOOM_BOTS_NFT_VERSION}&focusBotID=${botID}`
      httpClient.get(stateUrl, false).then((botsState:any) => {
        botsState.chainId = chainId
        updateStatsApiState(botsState)
        resolve(botsState)
      })
    })
  }

  // executes the poll for the new bot
  async function poll(botID: number) {
    async function nextIter() {
      const botsState = await refreshBotsStateForBotID(botID)
      const cond = pollTerminateCondition(botsState, botID)
      if(cond) return
      setTimeout(nextIter, 1000)
    }
    nextIter()
  }

  // returns true if the quick poll condition is satisfied and should terminate
  function pollTerminateCondition(botsState: any, botID: number) {
    if(!botsState || !!botsState.loading) return false
    if(botsState.botlist.hasOwnProperty(botID)) return true
    if(!!botsState.botlist[botID]) return true
    return false
  }

  return poll
}
