import { Connector } from '@web3-react/types'
import COINBASE_ICON_URL from 'assets/images/coinbaseWalletIcon.svg'
import { coinbaseWalletConnection, ConnectionType } from 'connection'
import { getConnectionName } from 'connection/utils'

import Option from './Option'

const BASE_PROPS = {
  color: '#315CF5',
  icon: COINBASE_ICON_URL,
  id: 'coinbase-wallet',
}

export function OpenCoinbaseWalletOption() {
  const isActive = coinbaseWalletConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      link="https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fapp.boombots.xyz"
      header="Open in Coinbase Wallet"
    />
  )
}

/*
https://docs.cloud.coinbase.com/wallet-sdk/docs/deep-link#universal-link

// uni https://go.cb-w.com/mtUDhEZPy1

https://go.cb-w.com/dapp?cb_url=[YOUR_URL]
https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Fnft.coinbase.com%2F%40barmstrong%3Fparam1%3Dhello%26param2%3Dworld
https://nft.coinbase.com/@barmstrong?param1=hello&param2=world
*/

export function CoinbaseWalletOption({ tryActivation }: { tryActivation: (connector: Connector) => void }) {
  const isActive = coinbaseWalletConnection.hooks.useIsActive()
  return (
    <Option
      {...BASE_PROPS}
      isActive={isActive}
      onClick={() => tryActivation(coinbaseWalletConnection.connector)}
      header={getConnectionName(ConnectionType.COINBASE_WALLET)}
    />
  )
}
