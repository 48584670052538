import { Currency, CurrencyAmount, TradeType } from '@uniswap/sdk-core'
import { useMemo } from 'react'
import { RouterPreference } from 'state/routing/slice'

/**
 * Returns query arguments for the Routing API query or undefined if the
 * query should be skipped. Input arguments do not need to be memoized, as they will
 * be destructured.
 */
export function useRoutingAPIArguments({
  tokenIn,
  tokenOut,
  amount,
  tradeType,
  routerPreference,
}: {
  tokenIn: Currency | undefined
  tokenOut: Currency | undefined
  amount: CurrencyAmount<Currency> | undefined
  tradeType: TradeType
  routerPreference: RouterPreference
}) {
  //console.log("In useRoutingAPIArguments()", "tokenIn:", tokenIn, "tokenOut:", tokenOut)
  //console.log("tokenIn:", tokenIn)
  //console.log("tokenOut:", tokenOut)
  //console.log("routerPreference:", routerPreference)
  return useMemo(
    /*
    () =>
      !tokenIn || !tokenOut || !amount || tokenIn.equals(tokenOut)
        ? undefined
        : {
            amount: amount.quotient.toString(),
            tokenInAddress: tokenIn.wrapped.address,
            //tokenInAddress: tokenIn.isNative ? GAS_TOKEN_ADDRESSES[tokenIn.wrapped.chainId] : tokenIn.wrapped.address,
            tokenInChainId: tokenIn.wrapped.chainId,
            tokenInDecimals: tokenIn.wrapped.decimals,
            tokenInSymbol: tokenIn.wrapped.symbol,
            tokenOutAddress: tokenOut.wrapped.address,
            //tokenOutAddress: tokenOut.isNative ? GAS_TOKEN_ADDRESSES[tokenOut.wrapped.chainId] : tokenOut.wrapped.address,
            tokenOutChainId: tokenOut.wrapped.chainId,
            tokenOutDecimals: tokenOut.wrapped.decimals,
            tokenOutSymbol: tokenOut.wrapped.symbol,
            routerPreference,
            type: (tradeType === TradeType.EXACT_INPUT ? 'exactIn' : 'exactOut') as 'exactIn' | 'exactOut',
          },
    */
    () => {
      if (!tokenIn || !tokenOut || !amount || tokenIn.equals(tokenOut)) return undefined
      //if(tokenIn.isNative) console.log("token in is native. switching for", GAS_TOKEN_ADDRESSES[tokenIn.wrapped.chainId])
      //if(tokenOut.isNative) console.log("token out is native. switching for", GAS_TOKEN_ADDRESSES[tokenIn.wrapped.chainId])
      return {
        amount: amount.quotient.toString(),
        tokenInAddress: tokenIn.wrapped.address,
        //tokenInAddress: tokenIn.isNative ? GAS_TOKEN_ADDRESSES[tokenIn.wrapped.chainId] : tokenIn.wrapped.address,
        tokenInChainId: tokenIn.wrapped.chainId,
        tokenInDecimals: tokenIn.wrapped.decimals,
        tokenInSymbol: tokenIn.wrapped.symbol,
        tokenOutAddress: tokenOut.wrapped.address,
        //tokenOutAddress: tokenOut.isNative ? GAS_TOKEN_ADDRESSES[tokenOut.wrapped.chainId] : tokenOut.wrapped.address,
        tokenOutChainId: tokenOut.wrapped.chainId,
        tokenOutDecimals: tokenOut.wrapped.decimals,
        tokenOutSymbol: tokenOut.wrapped.symbol,
        routerPreference,
        type: (tradeType === TradeType.EXACT_INPUT ? 'exactIn' : 'exactOut') as 'exactIn' | 'exactOut',
      }
    },
    [amount, routerPreference, tokenIn, tokenOut, tradeType]
  )
}
