import { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { PageWrapper } from '../../components/swap/styleds'
import { useIsDarkMode } from 'state/user/hooks'
import { useWeb3React } from '@web3-react/core'
import { useBotsState } from 'state/statsApi/hooks'
import CenteringDiv from 'components/centeringDiv'
import Loader from 'components/Loader'
import { useAllTokens, useCurrencies } from 'hooks/Tokens'
import ArrowLimitOrderWhite from '../../assets/images/arrow-limit-order-white2.png'
import ArrowGridOrderWhite from '../../assets/images/arrow-grid-order-white2.png'
import HydrogenNucleusHelper from 'lib/utils/HydrogenNucleusHelper'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { formatUnits } from 'ethers/lib/utils'
import { deduplicateArray } from 'lib/utils/arrays'
import { currencyAmountToString } from 'lib/utils/currencyAmountToString'
import { Price } from '@uniswap/sdk-core'
import TradePrice from 'components/swap/TradePrice'
import { formatTransactionAmount, priceToPreciseFloat } from 'utils/formatNumbers'
import { BigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import { determinePairOrder } from './determinePairOrder'
import { BOOM_BOTS_NFT_VERSION } from 'constants/index'
import { getChainInfoOrDefault } from 'constants/chainInfo'

const ExternalLink = styled.a`
  text-decoration: none;
  &:hover {
    color: #1348BF;
  }
`

const monospace = "'Roboto Mono',monospace"

/*
const moduleDisplay = (module:any, funcList:string[]) => {
  return (
    <CenteringDiv>
      <pre style={{fontFamily:monospace}}>{JSON.stringify(module, undefined, 2)}</pre>
    </CenteringDiv>
  )
}
*/

const etherscanLinkAccount = (explorer:string, account:string, size="sm") => {
  const account2 = ( (size == "sm")
    ? (<>{account.substring(0,21)}{<br/>}{account.substring(21,42)}</>)
    : account)

  return (
    <ExternalLink href={`${explorer}address/${account}`} target="_blank">
      <code style={{fontFamily:monospace}}>
        {account2}
      </code>
    </ExternalLink>
  )
}

const etherscanLinkToken = (explorer:string, symbol:string, address:string) => {
  //<ExternalLink href={`${explorer}token/${address}`} target="_blank">
  return (
    <ExternalLink href={`${explorer}address/${address}`} target="_blank">
      <code style={{fontFamily:monospace}}>
        {symbol}
      </code>
    </ExternalLink>
  )
}

const etherscanLinkNamed = (explorer:string, name:string, address:string) => {
  return (
    <ExternalLink href={`${explorer}address/${address}`} target="_blank">
      <code style={{fontFamily:monospace}}>
        {name}
      </code>
    </ExternalLink>
  )
}

function ModuleDisplay(props:any) {
  const { explorer, module, funcList } = props
  const text = `${Object.keys(funcList).map(x=>`  ${x}`).join('\n')}`
  return (
    <code style={{marginLeft:"10px"}}>
      {etherscanLinkNamed(explorer, module.name, module.address)}
      <pre style={{fontFamily:monospace}}>{text}</pre>
    </code>
  )
  //<pre style={{fontFamily:monospace}}>{`${JSON.stringify(module, undefined, 2)}\n${Object.keys(funcList).join('\n')}`}</pre>
}

export default function InstalledModulesContent(props:any) {
  const isDarkMode = useIsDarkMode()
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const botsState = useBotsState() as any
  const defaultTokens = useAllTokens() as any
  const { bot } = props

  return (
    <CenteringDiv>
      <p>Installed modules view coming soon</p>
    </CenteringDiv>
  )
  /*
  const {
    nativeCurrency: { symbol: nativeCurrencySymbol },
    explorer,
  } = getChainInfoOrDefault(chainId)

  const moduleList = [
    { address: "0xdD0b84cB4DA1a1D1c262Cc4009036417BB3165eb", name: "Module Pack 100" },
    { address: "0xD071924d2eD9cF44dB9a62A88A80E9bED9782711", name: "Ring Protocol Module A" },
  ]

  const functionSelectorList = [
    { selector: "", name: "" },
    { selector: "", name: "" },
    { selector: "", name: "" },
  ]

  const installedModulesContent = useMemo(() => {
    if(!bot || !bot.modules) return (
      <CenteringDiv>
        <Loader/>
      </CenteringDiv>
    )
    if(bot.modules.length == 0) return (
      <CenteringDiv>
        <p>No modules found</p>
      </CenteringDiv>
    )

    const modules2:any = {}
    for(const module of bot.modules) {
      const address = module.moduleAddress
      modules2[address] = {}
      for(const functionSelector of module.functionSelectors) {
        modules2[address][functionSelector] = true
      }
    }
    return (
      <div>
        <CenteringDiv>
          <p>Modules:</p>
        </CenteringDiv>
        {moduleList.map((module:any) => {
          const funcList = modules2[module.address]
          if(!funcList || Object.keys(funcList).length == 0) return undefined
          return <ModuleDisplay explorer={explorer} module={module} funcList={funcList}/>
        })}
      </div>
    )
  }, [bot])

  return installedModulesContent
  */
}
