import { updateStatsApiStateData, updateStatsApiBotInfo } from './actions'
import { createReducer } from '@reduxjs/toolkit'

export interface StatsApiState {
  readonly botsState: any
  readonly botInfo: any
}

export const initialState: StatsApiState = {
  botsState: { loading: true },
  botInfo: { loading: true },
}

export default createReducer(initialState, (builder) => {
  return builder
    .addCase(updateStatsApiStateData, (state, { payload: { apiResponse/*, networkId*/ } }) => {
      state.botsState = apiResponse
    })
    .addCase(updateStatsApiBotInfo, (state, { payload: { apiResponse/*, networkId*/ } }) => {
      state.botInfo = apiResponse
    })
})
