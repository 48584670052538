import { useUpdateStatsApiState } from './hooks'
import { useEffect, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { HttpClient } from './HttpClient'
import { BOOM_BOTS_NFT_VERSION } from 'constants/index'
import { useBotManagementState } from './../botManagement/hooks'

export default function Updater(): null {
  const { chainId } = useWeb3React()

  const updateStatsApiState = useUpdateStatsApiState()
  const httpClient = new HttpClient()
  const botManagementState = useBotManagementState()
  const { botID } = botManagementState
  /*
  function refreshBotsState() {
    if(!chainId) return
    //console.log("in updater.refreshBotsState")
    //console.log(botManagementState)
    const stateUrl = `https://stats.boombots.xyz/state/?chainID=${chainId}&v=${BOOM_BOTS_NFT_VERSION}`
    httpClient.get(stateUrl, false).then((botsState:any) => {
      botsState.chainId = chainId
      //console.log({botsState})
      updateStatsApiState(botsState)
    })
  }
  */
  //useMemo(() => {
    //console.log(`in useUpdateStatsApiState(). botManagementState:`)
    //console.log(botManagementState)
  //}, [botManagementState])

  const refreshBotsState = useMemo(() => {
    function f() {
      if(!chainId) return
      //console.log("in updater.refreshBotsState.f()")
      //console.log({chainId, botManagementState})
      //console.log({chainId, botID})
      let stateUrl = `https://stats.boombots.xyz/state/?chainID=${chainId}&v=${BOOM_BOTS_NFT_VERSION}`
      if(!!botID) stateUrl = `${stateUrl}&focusBotID=${botID}`
      httpClient.get(stateUrl, false).then((botsState:any) => {
        botsState.chainId = chainId
        updateStatsApiState(botsState)
      })
    }
    return f
  //}, [chainId, httpClient, updateStatsApiState, botManagementState])
  }, [chainId, botID])
  /*
  const refreshBotInfo = useMemo(() => {
    function f() {
      if(!chainId || !botID) return
      console.log("in updater.refreshBotInfo.g()")
      //console.log({chainId, botManagementState})
      console.log({chainId, botID})
      const botInfoUrl = `https://stats.boombots.xyz/bot?botID=${botID}&chainID=${chainId}&v=${BOOM_BOTS_NFT_VERSION}`
      console.log(`calling ${botInfoUrl}`)
      httpClient.get(botInfoUrl, false).then((botInfo:any) => {
        botInfo.chainId = chainId
        console.log("received botInfo")
        console.log(botInfo)
        //updateStatsApiState(botInfo)
      })
    }
    return f
  //}, [chainId, httpClient, updateStatsApiState, botManagementState])
  }, [chainId, botID])
  */
  /*
  const comboFunc = useMemo(() => {
    function f() {
      return Promise.all([
        refreshBotsState(),
        refreshBotInfo(),
      ])
    }
    return f
  }, [chainId, botID, refreshBotsState, refreshBotInfo])
  */
  useEffect(() => {
    refreshBotsState()
    const interval = setInterval(refreshBotsState, 30000)
    return () => clearInterval(interval)
  //}, [chainId, botID, refreshBotsState])
  }, [refreshBotsState])

  return null
}
