import { Trans } from '@lingui/macro'
import { SwapPriceUpdateUserResponse } from '@uniswap/analytics-events'
import { Currency, Percent, Price, TradeType } from '@uniswap/sdk-core'
import CenteringDiv from 'components/centeringDiv'
import { getPriceUpdateBasisPoints } from 'lib/utils/analytics'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useEffect, useMemo, useState } from 'react'
import { AlertTriangle, ArrowDown } from 'react-feather'
import { Text } from 'rebass'
import { InterfaceTrade } from 'state/routing/types'
import styled, { useTheme } from 'styled-components/macro'

import { useStablecoinValue } from '../../hooks/useStablecoinPrice'
import { ThemedText } from '../../theme'
import { isAddress, shortenAddress } from '../../utils'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { ButtonPrimary } from '../Button'
import { LightCard } from '../Card'
import { AutoColumn } from '../Column'
import { FiatValue } from '../CurrencyInputPanel/FiatValue'
import CurrencyLogo from '../Logo/CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import TradePrice from '../swap/TradePrice'
import { SwapShowAcceptChanges, TruncatedText } from './styleds'

export default function CreateModalHeader({
}: {
}) {
  const theme = useTheme()
  // swap state

  return (
    <AutoColumn gap="4px" style={{ marginTop: '1rem' }}>
    {/* footnote */}
      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '.75rem 1rem' }}>
        <ThemedText.DeprecatedItalic fontWeight={400} textAlign="left" style={{ width: '100%' }}>
          <Trans>
            The output of these trades will not be sent to your wallet. You are creating a pool that will hold the tokens to be traded. You may withdraw from your pool at any time.
          </Trans>
        </ThemedText.DeprecatedItalic>
      </AutoColumn>
    </AutoColumn>
  )

}
