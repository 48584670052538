import { Trans } from '@lingui/macro'
import { Trace } from '@uniswap/analytics'
import { ModalName } from '@uniswap/analytics-events'
import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { ReactNode, useCallback, useMemo, useState } from 'react'
//import { useDerivedGridOrderInfo, useGridOrderState } from 'state/gridOrder/hooks'
import { InterfaceTrade } from 'state/routing/types'
import { tradeMeaningfullyDiffers } from 'utils/tradeMeaningFullyDiffer'

import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from '../TransactionConfirmationModal'
import CreateModalFooter from './CreateModalFooter'
import CreateModalHeader from './CreateModalHeader'

export default function ConfirmCreateModal({
  onConfirm,
  onDismiss,
  isOpen,
  attemptingTxn,
  txHash,
  createdBotID,
}: {
  isOpen: boolean
  attemptingTxn: boolean
  txHash: string | undefined
  onConfirm: () => void
  onDismiss: () => void
  createdBotID?: number | undefined
}) {
  /*
  console.log("In ConfirmCreateModal")
  console.log({
    onConfirm,
    onDismiss,
    isOpen,
    attemptingTxn,
    txHash,
    createdBotID,
  })
  */
  /*
  useMemo(() => {
    console.log("In ConfirmCreateModal")
    console.log({
      onConfirm,
      onDismiss,
      isOpen,
      attemptingTxn,
      txHash,
      createdBotID,
    })
  }, [
    onConfirm,
    onDismiss,
    isOpen,
    attemptingTxn,
    txHash,
    createdBotID,
  ])
  */
  // shouldLogModalCloseEvent lets the child CreateModalHeader component know when modal has been closed
  // and an event triggered by modal closing should be logged.
  const [shouldLogModalCloseEvent, setShouldLogModalCloseEvent] = useState(false)

  const onModalDismiss = useCallback(() => {
    if (isOpen) setShouldLogModalCloseEvent(true)
    onDismiss()
  }, [isOpen, onDismiss])

  const modalHeader = useCallback(() => {
    return (
      <CreateModalHeader
      />
    )
  }, [])

  const swapErrorMessage = undefined
  const pendingText = "Create bot"

  const modalBottom = useCallback(() => {
    return (
      <CreateModalFooter
        onConfirm={onConfirm}
        disabledConfirm={false}
        swapErrorMessage={undefined}
      />
    )
  }, [
    onConfirm,
  ])

  //const pendingText = `Placing grid order with ${tokenList}`

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onModalDismiss} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title={<Trans>Confirm Grid Order</Trans>}
          onDismiss={onModalDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onModalDismiss, modalBottom, modalHeader, swapErrorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onModalDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={undefined}
      createdBotID={createdBotID}
    />
  )
}
