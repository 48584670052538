import { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { PageWrapper } from '../../components/swap/styleds'
import { useIsDarkMode } from 'state/user/hooks'
import { useWeb3React } from '@web3-react/core'
import { useBotsState } from 'state/statsApi/hooks'
import CenteringDiv from 'components/centeringDiv'
import Loader from 'components/Loader'
import { useAllTokens, useCurrencies } from 'hooks/Tokens'
import ArrowLimitOrderWhite from '../../assets/images/arrow-limit-order-white2.png'
import ArrowGridOrderWhite from '../../assets/images/arrow-grid-order-white2.png'
import HydrogenNucleusHelper from 'lib/utils/HydrogenNucleusHelper'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { formatUnits } from 'ethers/lib/utils'
import { deduplicateArray } from 'lib/utils/arrays'
import { currencyAmountToString } from 'lib/utils/currencyAmountToString'
import { Price } from '@uniswap/sdk-core'
import TradePrice from 'components/swap/TradePrice'
import { formatTransactionAmount, priceToPreciseFloat } from 'utils/formatNumbers'
import { BigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import { determinePairOrder } from './determinePairOrder'
import { BOOM_BOTS_NFT_VERSION } from 'constants/index'
import { getChainInfoOrDefault } from 'constants/chainInfo'

const ExternalLink = styled.a`
  text-decoration: none;
  &:hover {
    color: #1348BF;
  }
`

export default function BalancesContent(props:any) {
  const isDarkMode = useIsDarkMode()
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const botsState = useBotsState() as any
  const defaultTokens = useAllTokens() as any
  const { bot } = props

  const {
    nativeCurrency: { symbol: nativeCurrencySymbol },
    explorer,
  } = getChainInfoOrDefault(chainId)


  if(!bot || !bot.balances) return (
    <CenteringDiv>
      <Loader/>
    </CenteringDiv>
  )
  if(Object.keys(bot.balances).length == 0) return (
    <CenteringDiv>
      <p>No balances found</p>
    </CenteringDiv>
  )

  const gasTokenList = [
    { address: "0x0000000000000000000000000000000000000000", symbol: "ETH", decimals: 18 },
    { address: "0x0000000000000000000000000000000000000001", symbol: "All claimable gas", decimals: 18 },
    { address: "0x0000000000000000000000000000000000000002", symbol: "Max claimable gas", decimals: 18 },
  ]

  const erc20TokenList = [
    //{ address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE", symbol: "ETH", decimals: 18 },
    { address: "0x4200000000000000000000000000000000000023", symbol: "WETH", decimals: 18 },
    { address: "0x4200000000000000000000000000000000000022", symbol: "USDB", decimals: 18 },
    { address: "0x3114ded1fA1b406e270A65a21bC96E86C171a244", symbol: "Mock USDB", decimals: 18 },
    { address: "0xdBa6Cb5a91AE6F0ac3883F3841190c2BFa168f9b", symbol: "PreBOOM", decimals: 18 },
    { address: "0xF19A5b56b419170Aa2ee49E5c9195F5902D39BF1", symbol: "USDC", decimals: 6 },
    { address: "0xD8F542D710346DF26F28D6502A48F49fB2cFD19B", symbol: "USDT", decimals: 18 },
    { address: "0x9C6Fc5bF860A4a012C9De812002dB304AD04F581", symbol: "DAI", decimals: 18 },
    { address: "0x1B0cC80F4E2A7d205518A1Bf36de5bED686662FE", symbol: "BOLT", decimals: 18 },
    { address: "0x7647a41596c1Ca0127BaCaa25205b310A0436B4C", symbol: "RGB", decimals: 18 },
  ]
//const PRE_BOOM_ADDRESS           = "0xdBa6Cb5a91AE6F0ac3883F3841190c2BFa168f9b"; // v0.1.2
//const MOCK_USDB_ADDRESS          = "0x3114ded1fA1b406e270A65a21bC96E86C171a244"; // v0.1.1

  const monospace = "'Roboto Mono',monospace"
  const etherscanLinkAccount = (account:string, size="sm") => {
    const account2 = ( (size == "sm")
      ? (<>{account.substring(0,21)}{<br/>}{account.substring(21,42)}</>)
      : account)

    return (
      <ExternalLink href={`${explorer}address/${account}`} target="_blank">
        <code style={{fontFamily:monospace}}>
          {account2}
        </code>
      </ExternalLink>
    )
  }

  const etherscanLinkToken = (symbol:string, address:string) => {
    //<ExternalLink href={`${explorer}token/${address}`} target="_blank">
    return (
      <ExternalLink href={`${explorer}address/${address}`} target="_blank">
        <code style={{fontFamily:monospace}}>
          {symbol}
        </code>
      </ExternalLink>
    )
  }

  const gasBalances = gasTokenList.map((token:any) => {
    const { address, symbol, decimals } = token
    //const bal = bot.balances[address]
    //if(!bal || bal == "0") return undefined
    let bal = bot.balances[address] || "0"
    //if(!bal || bal == "0") return undefined
    if(address == "0x0000000000000000000000000000000000000000") return (
      <div style={{marginTop:"10px",marginBottom:"30px"}} key={address}>
        <img src={`https://assets.boombots.xyz/tokens/${address}`} alt={symbol} style={{display:"inline",width:"50px",position:"relative",top:"17px",marginLeft:"20px"}}/>
        <p style={{display:"inline",marginLeft:"20px",fontFamily:monospace}}>{`${formatUnits(bal, decimals)} `}</p>
        <code style={{fontFamily:monospace}}>
          {symbol}
        </code>
      </div>
    )
    return (
      <div style={{marginTop:"10px"}} key={address}>
        <p style={{display:"inline",marginLeft:"90px",fontFamily:monospace}}>{`${formatUnits(bal, decimals)} `}</p>
        <code style={{fontFamily:monospace}}>
          {symbol}
        </code>
      </div>
    )
  }).filter(x=>!!x)

  const erc20Balances = erc20TokenList.map((token:any) => {
    const { address, symbol, decimals } = token
    const bal = bot.balances[address]
    if(!bal || bal == "0") return undefined
    return (
      <div style={{marginTop:"10px"}} key={address}>
        <img src={`https://assets.boombots.xyz/tokens/${address}`} alt={symbol} style={{display:"inline",width:"50px",position:"relative",top:"17px",marginLeft:"20px"}}/>
        <p style={{display:"inline",marginLeft:"20px",fontFamily:monospace}}>{`${formatUnits(bal, decimals)} `}</p>
        {etherscanLinkToken(symbol, address)}
      </div>
    )
  }).filter(x=>!!x)

  return (
    <div>
      {gasBalances.length == 0 ? undefined : (
        <div style={{paddingTop:"20px"}}>
          <p style={{marginLeft:"20px"}}>Gas Token</p>
          {gasBalances}
        </div>
      )}
      {erc20Balances.length == 0 ? undefined : (
        <div style={{paddingTop:"20px"}}>
          <p style={{marginLeft:"20px"}}>ERC20 Tokens</p>
          {erc20Balances}
        </div>
      )}
    </div>
  )

}
