import { formatUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { Currency, TradeType } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Row, { AutoRow } from 'components/Row'
import { RoutingDiagramEntry } from 'components/swap/SwapRoute'
import { useTokenInfoFromActiveList } from 'hooks/useTokenInfoFromActiveList'
import { Box } from 'rebass'
import { InterfaceTrade } from 'state/routing/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { Z_INDEX } from 'theme/zIndex'

import { ReactComponent as DotLine } from '../../assets/svg/dot_line.svg'
import { MouseoverTooltip } from '../Tooltip'

const Wrapper = styled(Box)`
  align-items: center;
  width: 100%;
`

const RouteContainerRow = styled(Row)`
  display: grid;
  grid-template-columns: 24px 1fr 24px;
`

const RouteRow = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.1rem 0.5rem;
  position: relative;
`

const PoolBadge = styled(Badge)`
  display: flex;
  padding: 4px 4px;
`

const DottedLine = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: calc(100%);
  z-index: 1;
  opacity: 0.5;
`

const DotColor = styled(DotLine)`
  path {
    stroke: ${({ theme }) => theme.deprecated_bg4};
  }
`

const OpaqueBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 8px;
  display: grid;
  font-size: 12px;
  grid-gap: 4px;
  grid-auto-flow: column;
  justify-content: start;
  padding: 4px 6px 4px 4px;
  z-index: ${Z_INDEX.sticky};
`

const ProtocolBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.deprecated_bg3};
  border-radius: 4px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 10px;
  padding: 2px 4px;
  z-index: ${Z_INDEX.sticky + 1};
`

const MixedProtocolBadge = styled(ProtocolBadge)`
  width: 60px;
`

const BadgeText = styled(ThemedText.DeprecatedSmall)`
  word-break: normal;
`

export default function RoutingDiagram({
  currencyIn,
  currencyOut,
  routes,
  trade,
}: {
  currencyIn: Currency
  currencyOut: Currency
  routes: RoutingDiagramEntry[]
  trade: InterfaceTrade<Currency, Currency, TradeType>
}) {
  /*
  console.log("In RoutingDiagram", {
    currencyIn,
    currencyOut,
    routes,
    trade,
  })
  */
  const tokenIn = useTokenInfoFromActiveList(currencyIn)
  const tokenOut = useTokenInfoFromActiveList(currencyOut)
  //const trade2 = trade as any

  return (
    <Wrapper>
      {routes.map((entry, index) => (
        /*
        <RouteContainerRow key={index}>
          <>
            <CurrencyLogo currency={tokenIn} size="20px" />
            <Route entry={entry} />
            <CurrencyLogo currency={tokenOut} size="20px" />
            <br/>
            {Path({trade})}
          </>
        </RouteContainerRow>
        */
        <div key={index}>
          <RouteContainerRow key={index}>
            <CurrencyLogo currency={tokenIn} size="20px" />
            <Route entry={entry} />
            <CurrencyLogo currency={tokenOut} size="20px" />
          </RouteContainerRow>
          {Path({ trade })}
        </div>
      ))}
    </Wrapper>
  )
}

function Route({ entry: { percent, path, protocol } }: { entry: RoutingDiagramEntry }) {
  return (
    <RouteRow>
      <DottedLine>
        <DotColor />
      </DottedLine>
      <OpaqueBadge>
        {/*protocol === Protocol.MIXED ? (
          <MixedProtocolBadge>
            <BadgeText fontSize={12}>V3 + V2</BadgeText>
          </MixedProtocolBadge>
        ) : (
          <ProtocolBadge>
            <BadgeText fontSize={12}>{protocol.toUpperCase()}</BadgeText>
          </ProtocolBadge>
        )*/}
        <ProtocolBadge>
          <BadgeText fontSize={12}>TokenFlow</BadgeText>
        </ProtocolBadge>
        <BadgeText fontSize={14} style={{ minWidth: 'auto' }}>
          {percent.toSignificant(2)}%
        </BadgeText>
      </OpaqueBadge>
      <AutoRow gap="1px" width="100%" style={{ justifyContent: 'space-evenly', zIndex: 2 }}>
        {path.map(([currency0, currency1, feeAmount], index) => (
          <Pool key={index} currency0={currency0} currency1={currency1} feeAmount={feeAmount} />
        ))}
      </AutoRow>
    </RouteRow>
  )
}

function Pool({ currency0, currency1, feeAmount }: { currency0: Currency; currency1: Currency; feeAmount: FeeAmount }) {
  const tokenInfo0 = useTokenInfoFromActiveList(currency0)
  const tokenInfo1 = useTokenInfoFromActiveList(currency1)

  // TODO - link pool icon to analytics.boombots.xyz via query params
  return (
    <MouseoverTooltip
      text={<Trans>{tokenInfo0?.symbol + '/' + tokenInfo1?.symbol + ' ' + feeAmount / 10000}% pool</Trans>}
    >
      <PoolBadge>
        <Box margin="0 4px 0 12px">
          <DoubleCurrencyLogo currency0={tokenInfo1} currency1={tokenInfo0} size={20} />
        </Box>
        <ThemedText.DeprecatedSmall fontSize={14}>{feeAmount / 10000}%</ThemedText.DeprecatedSmall>
      </PoolBadge>
    </MouseoverTooltip>
  )
}

//function Path({ trade }): { trade: any } {
//function Path({ trade }): { trade: InterfaceTrade<Currency, Currency, TradeType> } {
//function Path({ trade }): JSX.Element {
/*
function Path(props: any) {
  const trade = props.trade
  const route = trade.routes[0]
  const vertices: any[] = []
  const imgStyle = {height: "24px", width: "24px", position: "relative", top: "6px"} as any
  const txt1Style = {display:"inline-block", margin:"0 0 0 12px", textAlign: "center"} as any
  const txt2Style = {display:"inline-block", margin:"0 12px 0 38px"}
  const txt3Style = {display:"inline-block", margin:"0 12px 0 12px"}
  for(var i = 0; i < route.routeDetailed.vertices.length; i++) {
    const vertex = route.routeDetailed.vertices[i]
    const vertexType = vertex.type
    let img1
    let txt1
    const txt2 = `${formatNumberPretty(vertex.amountIn, vertex.fromNode.decimals)} ${vertex.fromNode.symbol}`
    const img2 = <img src={`https://assets.boombots.xyz/tokens/${vertex.fromNode.symbol}`} alt="Token Logo" style={imgStyle}/>
    const txt3 = `for ${formatNumberPretty(vertex.amountOut, vertex.toNode.decimals)} ${vertex.toNode.symbol}`
    const img3 = <img src={`https://assets.boombots.xyz/tokens/${vertex.toNode.symbol}`} alt="Token Logo" style={imgStyle}/>
    if(vertexType.startsWith("Hu2")) {
      img1 = <img src="https://assets.boombots.xyz/protocol-logos/hysland_finance.png" alt="Blue Matter Technologies Logo" style={imgStyle}/>
      txt1 = vertex.function.includes("Deposit") ? "Hu2 Deposit" : "Hu2 Withdraw"
    } else if(vertexType === "UniswapV2") {
      img1 = <img src="https://assets.boombots.xyz/protocol-logos/uniswap.png" alt="Uniswap Logo" style={imgStyle}/>
      txt1 = vertex.function.includes("Add") ? "Uniswap V2 Add Liquidity" : vertex.function.includes("Remove") ? "Uniswap V2 Remove Liquidity" : "Uniswap V2 Swap"
    } else if(vertexType === "WrappedGasToken") {
      img1 = <img src="https://assets.boombots.xyz/protocol-logos/weth.png" alt="WETH Logo" style={imgStyle}/>
      txt1 = vertex.function.includes("Wrap") ? "Wrap Gas Token" : "Unwrap Gas Token"
    } else if(vertexType.includes("Yearn")) {
      img1 = <img src="https://assets.boombots.xyz/protocol-logos/yearn.png" alt="Yearn Logo" style={imgStyle}/>
      txt1 = vertex.function.includes("Deposit") ? "Yearn Deposit" : "Yearn Withdraw"
    } else {
      img1 = <img src="https://assets.boombots.xyz/placeholder.png" alt="Placeholder Logo" style={imgStyle}/>
      txt1 = "Unknown"
    }
    vertices.push(
      <div key={i} style={{paddingTop:"8px", paddingBottom:"12px", paddingLeft: "12px"}}>
        <div style={{height: "28px"}}>
          {img1}
          <p style={txt1Style}>{txt1}</p>
        </div>
        <div style={{height: "28px"}}>
          <p style={txt2Style}>{txt2}</p>
          {img2}
          <p style={txt3Style}>{txt3}</p>
          {img3}
        </div>
      </div>
    )
  }
  return (
    <div>
      {vertices}
    </div>
  )
}
*/

function Path(props: any) {
  const trade = props.trade
  const route = trade.routes[0]
  //console.log("In Path", props, route.routeDetailed)
  //const vertices: any[] = []
  const elements = []
  elements.push(Token(route.routeDetailed.path[0], route.routeDetailed.vertices[0].amountIn))
  //const symbol = formatSymbolPretty(props.vertex.fromNode.symbol)
  //const amount = formatNumberPretty(props.vertex.amountIn, props.vertex.fromNode.decimals)
  //function Token(amount: any, symbol: string, decimals: any) {
  const vertexStyle = {
    height: '28px',
    float: 'right',
    marginTop: '28px',
    position: 'relative',
    top: '-28px',
  } as any
  const imgStyle = {
    height: '24px',
    width: '24px',
    position: 'relative',
    top: '6px',
  } as any
  const txt1Style = {
    display: 'inline-block',
    margin: '0 12px 0 0',
    textAlign: 'center',
  } as any
  //const txt2Style = {display:"inline-block", margin:"0 12px 0 38px"}
  //const txt3Style = {display:"inline-block", margin:"0 12px 0 12px"}
  for (let vertexIndex = 0; vertexIndex < route.routeDetailed.vertices.length; vertexIndex++) {
    const vertex = route.routeDetailed.vertices[vertexIndex]
    const vertexType = vertex.type
    const tokenOutIndex = 2 * vertexIndex + 2
    //let img1
    //let txt1
    //const txt2 = `${formatNumberPretty(vertex.amountIn, vertex.fromNode.decimals)} ${vertex.fromNode.symbol}`
    //const img2 = <img src={`https://assets.boombots.xyz/tokens/${vertex.fromNode.symbol}`} alt="Token Logo" style={imgStyle}/>
    //const txt3 = `for ${formatNumberPretty(vertex.amountOut, vertex.toNode.decimals)} ${vertex.toNode.symbol}`
    //const img3 = <img src={`https://assets.boombots.xyz/tokens/${vertex.toNode.symbol}`} alt="Token Logo" style={imgStyle}/>
    if (vertexType.startsWith('Hu2')) {
      if (vertex.function.includes('Base')) continue // 1:1, ignore
      // double backwards logic
      const vertexText = vertex.function.includes('Withdraw') ? 'Yearn Deposit' : 'Yearn Withdraw'
      elements.push(
        <div style={vertexStyle}>
          <p style={txt1Style}>{vertexText}</p>
          <img src="https://assets.boombots.xyz/protocol-logos/yearn.png" alt="Yearn Logo" style={imgStyle} />
        </div>
      )
      elements.push(Token(route.routeDetailed.path[tokenOutIndex], route.routeDetailed.vertices[vertexIndex].amountOut))
      //img1 = <img src="https://assets.boombots.xyz/protocol-logos/hysland_finance.png" alt="Blue Matter Technologies Logo" style={imgStyle}/>
      //txt1 = vertex.function.includes("Deposit") ? "Hu2 Deposit" : "Hu2 Withdraw"
    } else if (vertexType === 'UniswapV2') {
      const vertexText = vertex.function.includes('Add')
        ? 'Uniswap V2 Add Liquidity'
        : vertex.function.includes('Remove')
        ? 'Uniswap V2 Remove Liquidity'
        : 'Uniswap V2 Swap'
      elements.push(
        <div style={vertexStyle}>
          <p style={txt1Style}>{vertexText}</p>
          <img src="https://assets.boombots.xyz/protocol-logos/uniswap.png" alt="Uniswap Logo" style={imgStyle} />
        </div>
      )
      //console.log(`In Uniswap V2 vertex. Creating token from: vertexIndex=${vertexIndex}, tokenOutIndex=${tokenOutIndex}`, route.routeDetailed.path[tokenOutIndex], route.routeDetailed.vertices[vertexIndex].amountOut)
      elements.push(Token(route.routeDetailed.path[tokenOutIndex], route.routeDetailed.vertices[vertexIndex].amountOut))
    } else if (vertexType === 'WrappedGasToken') {
      continue // 1:1, ignore
      /*
      let vertexText = vertex.function.includes("Wrap") ? "Wrap Gas Token" : "Unwrap Gas Token"
      elements.push(
        <div style={vertexStyle}>
          <p style={txt1Style}>{vertexText}</p>
          <img src="https://assets.boombots.xyz/protocol-logos/weth.png" alt="WETH Logo" style={imgStyle}/>
        </div>
      )
      elements.push(Token(route.routeDetailed.path[tokenOutIndex], route.routeDetailed.vertices[vertexIndex].amountOut))
      */
    } else if (vertexType.includes('Yearn')) {
      const vertexText = vertex.function.includes('Deposit') ? 'Yearn Deposit' : 'Yearn Withdraw'
      elements.push(
        <div style={vertexStyle}>
          <p style={txt1Style}>{vertexText}</p>
          <img src="https://assets.boombots.xyz/protocol-logos/yearn.png" alt="Yearn Logo" style={imgStyle} />
        </div>
      )
      elements.push(Token(route.routeDetailed.path[tokenOutIndex], route.routeDetailed.vertices[vertexIndex].amountOut))
    } else {
      elements.push(
        <div style={vertexStyle}>
          <p style={txt1Style}>Unknown</p>
          <img src="https://assets.boombots.xyz/placeholder.png" alt="Placeholder Logo" style={imgStyle} />
        </div>
      )
      elements.push(Token(route.routeDetailed.path[tokenOutIndex], route.routeDetailed.vertices[vertexIndex].amountOut))
    }
    /*
    elements.push(
      <div key={i} style={{paddingTop:"8px", paddingBottom:"12px", paddingLeft: "12px"}}>
        <div style={vertexStyle}>
          {img1}
          <p style={txt1Style}>{txt1}</p>
        </div>
        <div style={vertexStyle}>
          <p style={txt2Style}>{txt2}</p>
          {img2}
          <p style={txt3Style}>{txt3}</p>
          {img3}
        </div>
      </div>
    )
    */
  }
  return <div>{elements}</div>
}

function Token(tokenData: any, amount: any) {
  //console.log("Constructing token from", tokenData, amount)
  //const symbol = formatSymbolPretty(props.vertex.fromNode.symbol)
  //const amount = formatNumberPretty(props.vertex.amountIn, props.vertex.fromNode.decimals)
  const tokenStyle = { height: '28px', marginTop: '28px' }
  const symbolFormatted = formatSymbolPretty(tokenData.symbol)
  const amountFormatted = formatNumberPretty(amount, tokenData.decimals)
  //function Token(amount: any, symbol: string, decimals: any) {
  //const symbolFormatted = formatSymbolPretty(symbol)
  //const amountFormatted = formatNumberPretty(amount, decimals)
  return (
    <div style={tokenStyle}>
      <img
        src={`https://assets.boombots.xyz/tokens/${symbolFormatted}`}
        alt={`${symbolFormatted} Logo`}
        style={{
          height: '24px',
          width: '24px',
          position: 'relative',
          top: '6px',
        }}
      />
      <p
        style={{
          display: 'inline-block',
          margin: '0 0 0 12px',
          textAlign: 'center',
        }}
      >{`${amountFormatted} ${symbolFormatted}`}</p>
    </div>
  )
}

// num to string
function formatNumberPretty(num: any, decimals: any) {
  //function formatNumberPretty(num, decimals) {
  // toString
  const s = formatUnits(num, decimals)
  // separate left and right
  const dotIndex = s.indexOf('.')
  let left
  let right
  if (dotIndex == -1) {
    // if no decimals, pretty print integer
    left = s
    right = ''
  } else {
    // if decimals
    left = s.substring(0, dotIndex)
    right = s.substring(dotIndex + 1, s.length)
    // if 5 or more digits before decimal, strip decimals
    if (left.length >= 5) right = ''
    // trim sig figs after decimal
    const sigFigs = 3
    if (left === '0') {
      for (let i = 0; i < right.length; ++i) {
        if (right[i] !== '0') {
          right = right.substring(0, i + sigFigs)
          break
        }
      }
    } else {
      right = right.substring(0, Math.max(0, sigFigs - left.length))
    }
    // strip trailing zeros in decimals
    while (right.length > 1 && right[right.length - 1] === '0') right = right.substring(0, right.length - 1)
  }
  // add commas before decimals
  let counter = 0
  for (let i = left.length - 1; i > 0; i--) {
    counter++
    if (counter === 3) {
      counter = 0
      left = `${left.substring(0, i)},${left.substring(i, left.length)}`
    }
  }
  // join left and right and return
  if (right.length > 0) return `${left}.${right}`
  else return left
}

function formatSymbolPretty(symbol: string) {
  if (symbol.startsWith('hu2yv')) return symbol.substring(5, symbol.length)
  return symbol
}
