import { useMemo, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { PageWrapper } from '../../components/swap/styleds'
import { useIsDarkMode } from 'state/user/hooks'
import { useWeb3React } from '@web3-react/core'
import { useBotsState } from 'state/statsApi/hooks'
import CenteringDiv from 'components/centeringDiv'
import Loader from 'components/Loader'
import { useAllTokens, useCurrencies } from 'hooks/Tokens'
import ArrowLimitOrderWhite from '../../assets/images/arrow-limit-order-white2.png'
import ArrowGridOrderWhite from '../../assets/images/arrow-grid-order-white2.png'
//import HydrogenNucleusHelper from 'lib/utils/HydrogenNucleusHelper'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { formatUnits } from 'ethers/lib/utils'
import { deduplicateArray } from 'lib/utils/arrays'
import { currencyAmountToString } from 'lib/utils/currencyAmountToString'
import { Price } from '@uniswap/sdk-core'
import TradePrice from 'components/swap/TradePrice'
import { formatTransactionAmount, priceToPreciseFloat } from 'utils/formatNumbers'
import { BigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import BotCard from './BotCard'
import BotCardInListContainer from './BotCardInListContainer'

const PageText = styled.p`
  font-size: 24px;
`

const PageButton = styled.span`
  padding: 10px 10px;
  margin: 0 10px;
`

const PageButtonActive = styled(PageButton)`
  color: rgb(33, 114, 229);
`

const PageButtonInactive = styled(PageButton)`
  color: gray;
`

const PageButtonTextActive = styled.p`
  font-size: 24px;
  color: rgb(33, 114, 229);
  cursor: pointer;
`

const PageButtonTextInactive = styled.p`
  font-size: 24px;
  color: gray;
  cursor: not-allowed;
`

//<span style={{height:"20px",width:"20px",backgroundColor:"blue"}} onClick={incrementPageNumber}/>
//<span style={{height:"20px",width:"20px",margin:"0 10px",color:"rgb(33, 114, 229)"}} onClick={func}>{sym}</span>

export default function BotListPaginated(props:any) {

  const { botIDs } = props

  const pageSize = 5
  const [pageNumber, setPageNumber] = useState(1)
  const [lowPageNumber, highPageNumber, pageNumberCleaned] = useMemo(() => {
    if(botIDs.length == 0) return [0, 0, 0]
    const lowPageNumber = 1
    const highPageNumber = Math.ceil(botIDs.length / pageSize)
    const pageNumberCleaned = Math.min(highPageNumber, pageNumber)
    return [lowPageNumber, highPageNumber, pageNumberCleaned]
  }, [botIDs, pageSize, pageNumber, setPageNumber])

  const decrementPageNumber = () => {
    let newPageNumber = pageNumberCleaned-1
    if(newPageNumber <= lowPageNumber) newPageNumber = lowPageNumber
    setPageNumber(newPageNumber)
  }
  const incrementPageNumber = () => {
    let newPageNumber = pageNumberCleaned+1
    if(newPageNumber >= highPageNumber) newPageNumber = highPageNumber
    setPageNumber(newPageNumber)
  }
  const pageBotIDs = useMemo(() => {
    const startIndex = pageSize*(pageNumberCleaned-1)
    const stopIndex = startIndex + pageSize - 1
    const pageBotIDs = []
    for(let i = startIndex; i <= stopIndex; i++) {
      if(i >= botIDs.length) continue
      const botID = botIDs[i]
      pageBotIDs.push(botID)
    }
    return pageBotIDs
  }, [botIDs, pageSize, pageNumberCleaned])
  const pageBots = useMemo(() => {
    return (
      <div>
        {pageBotIDs.map((botID:string) => (
          <BotCardInListContainer botID={botID} key={botID}/>
        ))}
      </div>
    )
  }, [pageBotIDs])
  const [pageText, canDecrement, canIncrement] = useMemo(() => {
    if(botIDs.length == 0) return [`Page 0 of 0`, false, false]
    return [`Page ${pageNumberCleaned} of ${highPageNumber}`, pageNumberCleaned>1, pageNumberCleaned<highPageNumber]
  }, [botIDs, pageSize, pageNumberCleaned])

  //useMemo(() => {
    //console.log("in BotListPaginated")
    //console.log({botIDs, pageBotIDs, pageSize, pageNumber, pageNumberCleaned})
  //}, [botIDs, pageBotIDs, pageSize, pageNumber, pageNumberCleaned])

  /*
  const pageButton = (sym:string, func:any, isActive:boolean=true) => {
    return isActive ? (
      <PageButtonActive onClick={func}>{sym}</PageButtonActive>
    ) : (
      <PageButtonInactive onClick={func}>{sym}</PageButtonInactive>
    )
  }
  */
  const pageButton = (sym:string, func:any, isActive:boolean=true) => {
    const text = isActive ? <PageButtonTextActive>{sym}</PageButtonTextActive> : <PageButtonTextInactive>{sym}</PageButtonTextInactive>
    return <PageButton onClick={func}>{text}</PageButton>
  }

  const pageButtonLeft = pageButton('←', decrementPageNumber, canDecrement)
  const pageButtonRight = pageButton('→', incrementPageNumber, canIncrement)

  const controls = (
    <div>
      <div style={{height:"20px"}}>
      </div>
      <CenteringDiv>
        {pageButtonLeft}
        <PageText>{pageText}</PageText>
        {pageButtonRight}
      </CenteringDiv>
    </div>
  )

  return (
    <div>
      {pageBots}
      {controls}
    </div>
  )
}
