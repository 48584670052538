export const APP_URL = "app.boombots.xyz"
export const ANALYTICS_URL = "analytics.boombots.xyz"

//export const NUCLEUS_VERSION = "v1.0.0"
//export const NUCLEUS_ADDRESS = "0x1Caba1EaA6F14b94EF732624Db1702eA41b718ff"
//export const NUCLEUS_VERSION = "v1.0.1"
//export const NUCLEUS_ADDRESS = "0x49FD8f704a54FB6226e2F14B4761bf6Be84ADF15"
export const NUCLEUS_VERSION = "v0.1.0"
export const NUCLEUS_ADDRESS = "0x49FD8f704a54FB6226e2F14B4761bf6Be84ADF15"



// v0.1.0
//export const BOOM_BOTS_NFT_VERSION = "v0.1.0"
//export const BOOM_BOTS_NFT_ADDRESS = "0x2b119FA2796215f627344509581D8F39D742317F"
//export const BOOM_BOTS_FACTORY_ADDRESS = "0x53A4f1C1b2D9603B3D3ae057B075a0EDC3d7A615"

// v0.1.1
//export const BOOM_BOTS_NFT_VERSION = "v0.1.1"
//export const BOOM_BOTS_NFT_ADDRESS = "0xB3856D22fE476892Af3Cc6dee3D84F015AD5F5b1"
//export const BOOM_BOTS_FACTORY01_ADDRESS     = "0x0B0eEBa9CC8035D8EB2516835E57716f0eAE7B73"; // v0.1.1
//export const BOOM_BOTS_FACTORY02_ADDRESS     = "0xf57E8cCFD2a415aEc9319E5bc1ABD19aAF130bA1"; // v0.1.1
//export const BOOM_BOTS_FACTORY_ADDRESS = BOOM_BOTS_FACTORY02_ADDRESS

export const BOOM_BOTS_NFT_VERSION       = "v0.1.2"
export const BOOM_BOTS_NFT_ADDRESS       = "0x7724cc10B42760d4C624d6b81C4367118194E39B"
export const BOOM_BOTS_FACTORY01_ADDRESS = "0x92e795B8D78eA13a564da4F4E03965FBB89cb788"; // v0.1.2
export const BOOM_BOTS_FACTORY02_ADDRESS = "0x4acb9D0243dF085B4F59683cee2F36597334bDa4"; // v0.1.2
export const BOOM_BOTS_FACTORY_ADDRESS   = BOOM_BOTS_FACTORY02_ADDRESS
