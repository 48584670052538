import { Trans } from '@lingui/macro'
import { Trace, TraceEvent } from '@uniswap/analytics'
import { BrowserEvent, ElementName, EventName, PageName, SectionName } from '@uniswap/analytics-events'
import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'
import { useWeb3React } from '@web3-react/core'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import SwapDetailsDropdown from 'components/swap/SwapDetailsDropdown'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal'
import { MouseoverTooltip } from 'components/Tooltip'
import { isSupportedChain } from 'constants/chains'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import JSBI from 'jsbi'
import { formatSwapQuoteReceivedEventProperties } from 'lib/utils/analytics'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactNode } from 'react'
import { AlertTriangle, ArrowDown, CheckCircle, HelpCircle, Info } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useToggleWalletModal } from 'state/application/hooks'
import { InterfaceTrade } from 'state/routing/types'
import { TradeState } from 'state/routing/types'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { currencyAmountToPreciseFloat, formatTransactionAmount } from 'utils/formatNumbers'

import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary, ButtonYellow } from '../../components/Button'
import { GrayCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import SwapCurrencyInputPanel from '../../components/CurrencyInputPanel/SwapCurrencyInputPanel'
import Loader from '../../components/Loader'
import { AutoRow } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmCreateModal from '../../components/create/ConfirmCreateModal'
import { ArrowWrapper, PageWrapper, SwapCallbackError, SwapWrapper } from '../../components/swap/styleds'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { TOKEN_SHORTHANDS } from '../../constants/tokens'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useStablecoinValue } from '../../hooks/useStablecoinPrice'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/limitOrder/actions'
import { useExpertModeManager } from '../../state/user/hooks'
import { LinkStyledButton, ThemedText } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../utils/prices'
import { supportedChainId } from '../../utils/supportedChainId'
import { BigNumber } from 'ethers/lib/ethers'
import CenteringDiv from 'components/centeringDiv'
import { usePollStatsApiForBotID } from 'state/statsApi/hooks'
import { BOOM_BOTS_FACTORY_ADDRESSES } from 'constants/addresses'
import { BOOM_BOTS_NFT_VERSION } from 'constants/index'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import type { TransactionResponse } from '@ethersproject/providers'
import { TransactionType } from 'state/transactions/types'
import {
  useBotManagementActionHandlers,
} from '../../state/botManagement/hooks'
import { getChainInfo } from 'constants/chainInfo'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { SupportedChainId } from 'constants/chains'

const ArrowContainer = styled.div`
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapSection = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.backgroundModule};
  border-radius: 12px;
  padding: 16px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 1px solid ${({ theme }) => theme.backgroundModule};
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.stateOverlayPressed};
  }
`

const OutputSwapSection = styled(SwapSection)<{ showDetailsDropdown: boolean }>`
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundSurface}`};
  border-bottom-left-radius: ${({ showDetailsDropdown }) => showDetailsDropdown && '0'};
  border-bottom-right-radius: ${({ showDetailsDropdown }) => showDetailsDropdown && '0'};
`

const DetailsSwapSection = styled(SwapSection)`
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`


export default function CreatePage({ className }: { className?: string }) {
  const navigate = useNavigate()
  const { account, chainId, provider } = useWeb3React()

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal()

  // modal and loading
  const [{ showConfirm, errorMessage, attemptingTxn, txHash, createdBotID }, setModalState] = useState<{
    showConfirm: boolean
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
    createdBotID: number | undefined
  }>({
    showConfirm: false,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined,
    createdBotID: 0,
  })

  const addTransaction = useTransactionAdder()

  const pollStatsApiForBotID = usePollStatsApiForBotID()

  const isArgentWallet = useIsArgentWallet()

  const handleConfirmDismiss = useCallback(() => {
    console.log("in handleConfirmDismiss()")
    setModalState({ showConfirm: false, attemptingTxn, errorMessage, txHash, createdBotID: 0 })
  }, [attemptingTxn, errorMessage, txHash, createdBotID, setModalState])

  const { onChangeBotID } = useBotManagementActionHandlers()

  async function onChangeBotIDWithDelay(newBotID:any) {
    async function _sleeper(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    await _sleeper(0);
    onChangeBotID(newBotID)
  }

  //const onConfirmCreate = () => {
  //const onConfirmCreate = useCallback(() => {
  async function onConfirmCreate() {
    //console.log("in onConfirmCreate()")
    if(!chainId || !account || !provider || !setModalState) return
    let to = BOOM_BOTS_FACTORY_ADDRESSES[chainId]
    if(!to) return
    //let calldata = "0x494adbda" // createBot()
    let calldata = "0x69004a5c0000000000000000000000000000000000000000000000000000000000000004" // createBot(4)
    let txn: { to: string; data: string; value: string } = {
      to: to,
      data: calldata,
      value: '0',
    }

    //if(!!createdBotID) setCreatedBotID(undefined)
    //setModalState({ attemptingTxn: true, showConfirm, errorMessage: undefined, txHash: undefined })
    setModalState({ attemptingTxn: true, showConfirm:true, errorMessage: undefined, txHash: undefined, createdBotID: 0 })

    provider
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
        }

        return provider
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            //console.log("sent transaction. setting modal state")
            //console.log({ attemptingTxn: true, showConfirm: true, errorMessage: undefined, txHash: response.hash })
            setModalState({ attemptingTxn: true, showConfirm: true, errorMessage: undefined, txHash: response.hash, createdBotID: -1 })
            addTransaction(response, {
              type: TransactionType.CREATE_BOT,
            })
            response.wait(1).then((receipt:any)=> {
              //const botID = BigNumber.from(receipt.logs[0].topics[1]).toNumber()
              const createEvent = receipt.logs.filter((log:any) => log.topics.includes('0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'))[0]
              const botID = BigNumber.from(createEvent.topics[3]).toNumber()
              //console.log(`created bot ID ${botID}`)
              onChangeBotIDWithDelay(botID)
              //setCreatedBotID(botID)
              setModalState({ attemptingTxn: true, showConfirm: true, errorMessage: undefined, txHash: response.hash, createdBotID: botID })
              pollStatsApiForBotID(botID)
            })
          })

      })
      .catch((error) => {
        console.error('Failed to send transaction', error)
        setModalState({
          attemptingTxn: false,
          showConfirm,
          errorMessage: error.message,
          txHash: undefined,
          createdBotID: 0
        })
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })

  }

  //console.log({chainId, exists: !!chainId})
  //const info = chainId ? getChainInfo(chainId) : undefined

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState<SupportedChainId | undefined>(undefined)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onSelectChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      setPendingChainId(undefined)
      setIsOpen(false)
    },
    [selectChain, setIsOpen]
  )

  const targetChain = 168587773


  const button = useMemo(() => {
    if((!account || !chainId)) return (
      <ButtonLight onClick={toggleWalletModal} fontWeight={600}>
        <Trans>Connect Wallet</Trans>
      </ButtonLight>
    )
    if(chainId != targetChain) return (
      <ButtonLight onClick={() => onSelectChain(targetChain)} fontWeight={600}>
        <Trans>Connect Wallet to Blast Sepolia</Trans>
      </ButtonLight>
    )
    return (
      <ButtonPrimary onClick={onConfirmCreate} fontWeight={600}>
        <Trans>Create bot</Trans>
      </ButtonPrimary>
    )
  }, [account, toggleWalletModal, onConfirmCreate, chainId])

  return (
    <>
      <PageWrapper>
        <SwapWrapper className={className} id="swap-page">

          <ConfirmCreateModal
            isOpen={showConfirm}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            onConfirm={onConfirmCreate}
            onDismiss={handleConfirmDismiss}
            createdBotID={createdBotID}
          />

          <div style={{padding:"0 10px 20px 10px"}}>
            <CenteringDiv><h1>Create a bot</h1></CenteringDiv>
            <p>Each bot is an ERC721 NFT and a smart account linked through ERC6551.</p>
            <p>This allows you to interact onchain as if you were the bot itself.</p>
            <p>BOOM! is currently in open beta on Blast Testnet.</p>
            <p>Do not use BOOM! with real assets.</p>
            <p>Connect your wallet and switch to Blast Testnet.</p>
            <p>Creating and using a bot is free minus testnet gas fees.</p>
            <p>Create a bot now for a chance at a whitelist spot when we launch on Blast Mainnet!</p>
            <div style={{height:"20px"}}/>
            {button}
          </div>



        </SwapWrapper>
        <NetworkAlert />
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  )
}
