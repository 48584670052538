import { useWeb3React } from "@web3-react/core"
import styled from 'styled-components/macro'
import CenteringDiv from "components/centeringDiv"
import Loader from "components/Loader"
import { getChainInfo } from "constants/chainInfo"
//import HydrogenNucleusHelper from "lib/utils/HydrogenNucleusHelper"
import TradePage from "pages/Trade"
import { useEffect, useMemo, useState, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useDefaultsFromURLSearch } from "state/bot/hooks"
import { useBotsState } from "state/statsApi/hooks"
import BotCard from 'components/Bot/BotCard'
import BotInnerPage from 'components/Bot/BotInnerPage'
//import LimitOrderBotPage from './LimitOrderBotPage'
//import GridOrderBotPage from './GridOrderBotPage'
import { Currency, CurrencyAmount, Percent, Price, Token, TradeType } from '@uniswap/sdk-core'
import { Field, PriceField, PairState, DepositState, WithdrawState, BotManagementState } from '../../state/botManagement/actions'
import {
  useDerivedBotManagementInfo,
  useBotManagementActionHandlers,
  useBotManagementState,
} from '../../state/botManagement/hooks'
import { determinePairOrder } from 'components/Bot/determinePairOrder'
import { formatTransactionAmount, priceToPreciseFloat } from 'utils/formatNumbers'
import { useCurrencies, useCurrenciesAndNatives, useCurrency } from '../../hooks/Tokens'
import { deduplicateArray } from 'lib/utils/arrays'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { formatUnits, Interface } from 'ethers/lib/utils'
import { BigNumber } from '@ethersproject/bignumber'
import useSelectChain from 'hooks/useSelectChain'
import useSyncChainQuery from 'hooks/useSyncChainQuery'
import { SupportedChainId } from 'constants/chains'
import { ButtonConfirmed, ButtonError, ButtonLight, ButtonPrimary, ButtonYellow } from '../../components/Button'
import { Trans } from '@lingui/macro'

export default function BotPage() {
  const navigate = useNavigate()
  const { account, chainId } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const botsState = useBotsState() as any
  // swap state
  const currentState = useBotManagementState()
  const { botID } = currentState
  const { onChangeBotID } = useBotManagementActionHandlers()

  // get botID from url params
  const botIDurl = useMemo(() => {
    const botID = loadedUrlParams.botid || loadedUrlParams.botId || loadedUrlParams.botID
    if(!!botID && botID > 0) return botID
    return undefined
  }, [loadedUrlParams])

  // if the botID is invalid, redirect to /trade
  useEffect(() => {
    if(!botIDurl) navigate('/trade/')
  }, [botIDurl])

  // if the bot was recently created give the stats api some time to pick it up
  const [searching, setSearching] = useState(true)
  useEffect(() => {
    setTimeout(() => setSearching(false), 30000)
  }, [botIDurl])

  const Loading = () => (
    <>
      <p style={{display:"inline", marginRight: "8px"}}>{`Loading bot ${botIDurl}`}</p>
      <Loader/>
    </>
  )

  //console.log({chainId, exists: !!chainId})
  //const info = chainId ? getChainInfo(chainId) : undefined

  const selectChain = useSelectChain()
  useSyncChainQuery()

  const [pendingChainId, setPendingChainId] = useState<SupportedChainId | undefined>(undefined)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onSelectChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      setPendingChainId(targetChainId)
      await selectChain(targetChainId)
      setPendingChainId(undefined)
      setIsOpen(false)
    },
    [selectChain, setIsOpen]
  )

  const targetChain = 168587773

  // chain swapping button
  if(!chainId) return (
    <CenteringDiv>
      <Loader/>
    </CenteringDiv>
  )
  if(chainId != targetChain) return (
    <div style={{paddingTop:"50px"}}>
      <CenteringDiv>
        <ButtonLight onClick={() => onSelectChain(targetChain)} fontWeight={600}>
          <Trans>Connect wallet to Blast Sepolia</Trans>
        </ButtonLight>
      </CenteringDiv>
    </div>
  )

  // if still fetching from stats api
  if(!botsState || !!botsState.loading) return <Loading/>

  async function onChangeBotIDWithDelay(botIDurl:any) {
    async function _sleeper(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    await _sleeper(0);
    onChangeBotID(botIDurl)
  }

  //console.log(`in BotPage`)
  //console.log({ botID, botIDurl })
  if(botID != botIDurl) {
    //console.log(`botID mismatch in BotPage, changing from ${botID} to ${botIDurl}`)
    onChangeBotIDWithDelay(botIDurl)
    return <Loading/>
  }

  /*
  const mismatch = useMemo(() => {
    return (botID != botIDurl)
  }, [botID, botIDurl])
  */


  const bot = botsState?.botlist[botID]
  //const balances = botsState?.internalBalancesByBot[botID]

  // if bot not known to stats api
  if(!bot /*|| !balances*/ ) {
    // if still in buffer time
    if(searching) return <Loading/>
    //if(mismatch) return <Loading/>
    // if time up
    else return (
      <>
        <CenteringDiv><p style={{margin:"4px"}}>{`BotID ${botID} does not exist on this network.`}</p></CenteringDiv>
        <CenteringDiv><p style={{margin:"0"}}>Check the botID and try again.</p></CenteringDiv>
      </>
    )
  }

  return (
    <BotInnerPage/>
    /*
    botID.substring(botID.length-3) === "001" ? (
      <LimitOrderBotPage/>
    ) : (
      <GridOrderBotPage/>
    )
    */
  )
}
